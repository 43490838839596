import createApp from './app.js'

import messagingService from './service.messaging.js'

const appConfig = {"apiKey":"AIzaSyAeG-F7iQwHw5crsc-KtcHe5hHWpHpPgTY","authDomain":"mahkamaty-530f1.firebaseapp.com","databaseURL":"https:\u002F\u002Fmahkamaty-530f1.firebaseio.com","projectId":"mahkamaty-530f1","storageBucket":"mahkamaty-530f1.appspot.com","messagingSenderId":"528492057199","appId":"1:528492057199:web:133ae4e51608e5e7fff39a","measurementId":"G-V6T8LVV1RR"}

export default async (ctx, inject) => {
  let firebase, session
  let firebaseReady = false

  const fire = {
    async appReady() {
      if (!firebaseReady) {
        ({ firebase, session } = await createApp(appConfig, ctx))
        firebaseReady = true;

        forceInject(ctx, inject, "fireModule", firebase)
      }
      return session
    },
    async ready() {
      await fire.appReady()

      let servicePromises = []

      if (process.client) {
        servicePromises = [
        fire.messagingReady(),

        ]
      }

      await Promise.all(servicePromises)
      return session
    }
  }

  if (process.server) {
  }

  if (process.client) {
    fire.messaging = null
    fire.messagingReady = async () => {
      if (!fire.messaging) {
        await fire.appReady()
        fire.messaging = await messagingService(session, firebase, ctx, inject)
      }

      return fire.messaging
    }
  }

  inject('fire', fire)
  ctx.$fire = fire
}

function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  if (typeof window !== "undefined" && window.$nuxt) {
  // If clause makes sure it's only run when ready() is called in a component, not in a plugin.
    window.$nuxt.$options[injectKey] = value
  }
}